<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Alianzas</h1>
    </div>

    <DataGrid :configuracion="datagrid_config" :data="alianzas" @ready="buscar_btns">
      <button class="btn btn-secondary mr-2" @click="obtener_alianzas">Recargar</button>
      <button class="btn btn-primary mr-2" @click="show_modal=true">Nuevo</button>
    </DataGrid>

    <Info v-if="show_modal" :alianza_id="alianza" @close="cerrar_modal"/>
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';
import Info from './Alianza/Info';

import api from '@/apps/analyticpoint/api/comite/alianzas';

export default {
  components: {
    DataGrid, Info
  },
  data() {
    return {
      datagrid_config: {
        name: 'alianzas',
        cols: {
          nombre: 'Nombre',
          codigo: 'Código',
          estatus: 'Estatus',
          opciones: 'Opciones'
        },
        mutators: {
          opciones(val, row, vue) {
            return '<div class="text-center"><button class="btn btn-secondary btn-action" data-alianza="'+row.id+'"><i class="fa-solid fa-pen-to-square"></i></button></div>';
          }
        },
        selector: false
      },
      alianzas: [],
      alianza: null,
      show_modal: false
    }
  },
  mounted() {
    this.obtener_alianzas();
  },
  methods: {
    async obtener_alianzas() {
      try {
        this.alianzas = (await api.obtener_alianzas()).data;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_btns() {
      document.querySelectorAll('.btn-action').forEach(btn => {
        btn.removeEventListener('click', this.abrir_modal);
        btn.addEventListener('click', this.abrir_modal);
      });
    },
    abrir_modal(e) {
      let btn = e.target;
      if (btn.tagName == 'I')
        btn = btn.parentElement;

      this.alianza = btn.dataset.alianza;
      this.show_modal = true;
    },
    cerrar_modal(update) {
      this.alianza = null;
      this.show_modal = false;

      if (update)
        this.obtener_alianzas();
    }
  }
}
</script>